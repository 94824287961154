<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__spoiler"></div>
      <div class="footer__content">
        <div class="footer__content-info">
          <div class="row">
            <div class="col footer__content-address">
              <div class="footer__content-logos d-flex align-items-center">
                <a class="footer__logo mr-2" href="/">
                  <img src="/img/me-logo.svg" alt="" title=""/>
                </a>
                <a class="footer__logo-ec" href="/">
                  <img src="/img/me-ec.png" alt="" title=""/>
                </a>
              </div>
              <div class="footer__content-location">
                123610, Москва, Краснопресненская наб. 12, подъезд 9
              </div>
              <div class="footer__content-socials d-flex align-items-center">
                <a href="#" class="footer__content-socials-icon socials-fb"></a>
                <a href="#" class="footer__content-socials-icon socials-vk"></a>
                <a href="#" class="footer__content-socials-icon socials-twitter"></a>
                <a href="#" class="footer__content-socials-icon socials-insta"></a>
                <a href="#" class="footer__content-socials-icon socials-ok"></a>
                <a href="#" class="footer__content-socials-icon socials-youtube"></a>
              </div>
            </div>



            <div v-if="$parent.menu" class="footer__content-menu">
              <div class="col-auto footer__content-nav">
                <nav>
                  <ul>
                    <li v-for="(list, index) in $parent.menu.footer.collumns.bottom1" :key="index">
                      <a :href="list.url">{{ list.name }}</a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="col-auto footer__content-nav">
                <nav>
                  <ul>
                    <li v-for="(list, index) in $parent.menu.footer.collumns.bottom2" :key="index">
                      <a :href="list.url">{{ list.name }}</a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="col-auto footer__content-nav">
                <nav>
                  <ul>
                    <li v-for="(list, index) in $parent.menu.footer.collumns.bottom3" :key="index">
                      <a :href="list.url">{{ list.name }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

          </div>
        </div>

        <div class="footer__copyright">
          <div class="row align-items-center">
            <div class="col-auto footer__copyright-logo">
              <a href="#"><img src="/img/me-logo-footer.svg" /></a>
            </div>
            <div class="col d-none d-sm-block footer__copyright-info">
              <p>Портал разработан на базе цифровой платформы АО «Российский эспортный центр»
                в рамках национального проекта «Международная кооперация и экспорт»</p>
            </div>
            <div class="col-md-auto col-12 mt-2 mt-md-0 footer__copyright-text">© {{ new Date().getFullYear() }} Все права защищены.</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSeminars"
}
</script>

<style scoped>

</style>