<template>
  <div class="seminars-tpl">
    <header class="header">
      <div class="header__wrapper">
        <a class="header__logo" href="/">
          <img src="/img/me-logo.svg" alt="" title=""/>
        </a>
        <a class="header__logo-ec" href="/">
          <img src="/img/me-ec.png" alt="" title=""/>
        </a>

        <nav class="header__navigation">
          <ul v-if="$parent.menu">
            <template v-for="(list, index) in $parent.menu.header.bottom">
              <li v-if="index < 5" :key="index">
                <a :href="list.url">{{ list.name }}</a>
              </li>
            </template>
            <li class="dropdown" :class="dropFirstDown ? 'show' : ''">
              <button class="btn dotted-btn dropdown-toggle dropdown-header-menu" type="button"
                      id="dropdownHeaderMenu"
                      @click="() => {this.dropFirstDown = !this.dropFirstDown}"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="dropdown-header-menu__dots">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="dots"><path d="M8.087 16.044a3.044 3.044 0 11-6.087 0 3.044 3.044 0 016.087 0zM19.044 16.044a3.044 3.044 0 11-6.088 0 3.044 3.044 0 016.088 0zM30 16.044a3.044 3.044 0 11-6.087 0 3.044 3.044 0 016.087 0z"></path></svg>
                </span>
              </button>
              <div class="dropdown-menu" :class="dropFirstDown ? 'show' : ''" aria-labelledby="dropdownHeaderMenu">
                <template v-for="(list, index) in $parent.menu.header.top">
                  <a :href="list.url" :key="index">{{ list.name }}</a>
                </template>
              </div>
            </li>
          </ul>
        </nav>

        <div class="header__user">
          <div class="dropdown" :class="dropSecondDown ? 'show' : ''">
            <button class="btn btn-secondary dropdown-toggle" @click="() => {this.dropSecondDown = !this.dropSecondDown}" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="header__user-wrapper">User</span>
            </button>
            <div class="dropdown-menu" :class="dropSecondDown ? 'show' : ''" aria-labelledby="dropdownMenuButton">
              <a v-if="user" class="dropdown-item" href="https://lk.exportcenter.ru/ru/main">
                {{user}}
              </a>
              <a v-else class="dropdown-item" href="https://lk.exportcenter.ru/ru/main">...</a>
            </div>
          </div>
        </div>
      </div>

    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderCalendar",
  props: ['user'],
  data() {
    return {
      dropFirstDown: false,
      dropSecondDown: false
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
  .dotted-btn {
    border: none;
    background: inherit;
    font-weight: 400;
    &:focus {
      box-shadow: none!important;
    }
  }
  .dropdown-menu a {
    min-width: 218px;
    padding: 14px 16px;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    color: black!important;
    &:hover {
      color: white!important;
      background-color: rgb(97, 119, 255);
    }
  }
  .dropdown-header-menu__dots {
    width: 30px;
    height: 30px;
    margin-left: 30px;
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
    }
  }
</style>